import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import logo from "./logo.png";
import { 
  ArrowRight, 
  Search, 
  AlertCircle, 
  CheckCircle2, 
  RefreshCw,
  LogOut,
  Server,
  Filter
} from 'lucide-react';

const App = () => {
  const [apis, setApis] = useState([]);
  const [filter, setFilter] = useState('all');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedApi, setSelectedApi] = useState(null);

  // Hardcoded credentials (in a real application, this should be handled securely)
  const validUsername = 'admin';
  const validPassword = 'Maida@123';

  const authenticate = () => {
    if (username === validUsername && password === validPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Invalid credentials');
    }
  };

  const fetchApis = useCallback(async () => {
    if (!isAuthenticated) return;
    setIsInitialLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://apps.maida.co:3000/get-apis');
      setApis(response.data);
    } catch (error) {
      setError('Failed to fetch APIs');
    } finally {
      setIsInitialLoading(false);
    }
  }, [isAuthenticated]);

  const checkApiStatus = useCallback(async () => {
    if (!isAuthenticated) return;
    setIsLoading(true);
    try {
      const promises = apis.map(api => axios.get(`https://apps.maida.co:3000/check-api?name=${api.name}`));
      const responses = await Promise.all(promises);
      const updatedApis = responses.map((response, index) => ({ 
        ...apis[index], 
        status: response.data.status,
        url: response.data.url || apis[index].url
      }));
      setApis(updatedApis);
    } catch (error) {
      console.error('Error checking API status:', error);
    } finally {
      setIsLoading(false);
    }
  }, [apis, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchApis();
    }
  }, [fetchApis, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && apis.length > 0) {
      const interval = setInterval(() => {
        checkApiStatus();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [apis, checkApiStatus, isAuthenticated]);

  const handleLogout = () => {
    setIsAuthenticated(false);
    setUsername('');
    setPassword('');
  };

  const filteredApis = apis.filter(api => {
    const matchesSearch = api.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = 
      filter === 'all' ? true :
      filter === 'online' ? api.status === 'Active' :
      filter === 'offline' ? api.status !== 'Active' : true;
    return matchesSearch && matchesFilter;
  });

  const redirectToApi = (api) => {
    if (api.url) {
      window.open(api.url, '_blank');
    } else {
      console.error(`No URL available for API: ${api.name}`);
      alert('API URL not available');
    }
  };

  const ApiDetailsModal = ({ api, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl p-6 max-w-lg w-full">
        <h2 className="text-xl font-bold mb-4">{api.name}</h2>
        <div className="space-y-2">
          <p><strong>URL:</strong> {api.url}</p>
          <p><strong>Status:</strong> {api.status}</p>
          <p><strong>Authentication:</strong> {api.requiresAuth ? `Required (${api.authType})` : 'Not Required'}</p>
          {/* Add more details */}
        </div>
        <button onClick={onClose} className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg">
          Close
        </button>
      </div>
    </div>
  );

  const groupedApis = filteredApis.reduce((acc, api) => {
    const group = api.name.split('-')[0].trim();
    if (!acc[group]) acc[group] = [];
    acc[group].push(api);
    return acc;
  }, {});

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4">
        <div className="bg-white rounded-2xl shadow-xl p-8 w-full max-w-md">
          <div className="flex justify-center mb-8">
            <img src={logo} alt="Logo" className="w-24 h-24" />
          </div>
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-8">Welcome Back</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Username</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="Enter your username"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="Enter your password"
              />
            </div>
            <button
              onClick={authenticate}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-200 flex items-center justify-center space-x-2"
            >
              <span>Login</span>
              <ArrowRight size={18} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-2xl shadow-lg p-6">
          {/* Header */}
          <div className="flex justify-between items-center mb-8">
            <div className="flex items-center space-x-4">
              <img src={logo} alt="Logo" className="w-12 h-12" />
              <h1 className="text-2xl font-bold text-gray-800">API Status Dashboard</h1>
            </div>
            <button
              onClick={handleLogout}
              className="flex items-center space-x-2 px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition duration-200"
            >
              <LogOut size={18} />
              <span>Logout</span>
            </button>
          </div>

          {/* Search and Filter */}
          <div className="flex flex-col md:flex-row justify-between gap-4 mb-6">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Search APIs..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            <div className="flex space-x-2">
              <div className="flex items-center bg-gray-100 rounded-lg p-1">
                <Filter size={20} className="text-gray-500 ml-2 mr-1" />
                {['all', 'online', 'offline'].map((option) => (
                  <button
                    key={option}
                    onClick={() => setFilter(option)}
                    className={`px-4 py-2 rounded-lg transition duration-200 ${
                      filter === option
                        ? 'bg-white shadow-sm text-blue-600'
                        : 'text-gray-600 hover:text-gray-900'
                    }`}
                  >
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </button>
                ))}
              </div>
              <button
                onClick={checkApiStatus}
                disabled={isLoading}
                className={`p-2 text-gray-600 hover:bg-gray-100 rounded-lg transition duration-200 ${
                  isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                title="Refresh Status"
              >
                <RefreshCw size={20} className={isLoading ? 'animate-spin' : ''} />
              </button>
            </div>
          </div>

          {/* Add error message display */}
          {error && (
            <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg flex items-center">
              <AlertCircle className="mr-2" size={20} />
              {error}
            </div>
          )}

          {/* Show loading state */}
          {isInitialLoading ? (
            <div className="flex items-center justify-center p-8">
              <RefreshCw className="animate-spin text-blue-600" size={32} />
              <span className="ml-2 text-gray-600">Loading APIs...</span>
            </div>
          ) : (
            /* Existing API List section */
            <div className="space-y-4">
              {Object.entries(groupedApis).map(([group, apis]) => (
                <div key={group}>
                  <h2 className="text-lg font-semibold mb-2">{group}</h2>
                  {apis.map((api, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition duration-200"
                    >
                      <div className="flex items-center space-x-4">
                        <Server className="text-gray-400" size={24} />
                        <div>
                          <h3 className="font-semibold text-gray-800">{api.name}</h3>
                          <p className="text-sm text-gray-500">{api.url}</p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <span
                          className={`flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                            api.status === 'Active'
                              ? 'bg-green-100 text-green-800'
                              : 'bg-red-100 text-red-800'
                          }`}
                        >
                          {api.status === 'Active' ? (
                            <CheckCircle2 size={16} className="mr-1" />
                          ) : (
                            <AlertCircle size={16} className="mr-1" />
                          )}
                          {api.status}
                        </span>
                        <button
                          onClick={() => redirectToApi(api)}
                          className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg transition duration-200"
                          title="Open API"
                        >
                          <ArrowRight size={20} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {selectedApi && <ApiDetailsModal api={selectedApi} onClose={() => setSelectedApi(null)} />}
    </div>
  );
};

export default App;